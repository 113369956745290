import React from 'react'
import { graphql } from 'gatsby'
import FestifHelmet from '../components/Helmet/FestifHelmet'
import Wolflayout from '../components/wolflayout'
import Banner from '../components/NewTheme/Ui/Banner'
import FilteredActivities from '../components/NewTheme/Programmation/FilteredActivities'

const ConferencesEtAteliers = ({data}) => {
  const metaTitle = 'Conférences et ateliers | Le Festif! de Baie-Saint-Paul'
  const metaDescription = ''
  const metaKeywords = 'festival, musique, évènement, spectacles, québec, charlevoix, baie-saint-paul, conférence, atelier'

  const conferencesEvents = data.allPrismicEvents.edges.filter(({ node }) => {
    // return node.data.is_show === true && node.data.isconference === true
    return node.data.isconference === true
  })

  return (
    <Wolflayout mobileTitle={'Conférences'}>
        <FestifHelmet title={metaTitle} description={metaDescription} keywords={metaKeywords} />
        <Banner title="Conférences" titleLine2="et ateliers" />
        <div className="container content pb-12">

            {/* TOP TEXT */}
            <div className='text-center'>
                <h4 className='!font-obviously !font-regular !text-xl'>Le développement durable, c'est au cœur de nos valeurs et, cette année, on s'associe à la Région de biosphère de Charlevoix pour en faire encore plus! On aime se rassembler pour échanger et découvrir ensemble des idées et des pratiques pour un avenir plus vert. Pendant le festival, on t’invite à venir t'informer sur les bonnes pratiques du festivalier et discuter autour d'activités ludiques, de présentations de différents partenaires et d'ateliers sur des sujets qui font du bien à la planète. C’est un moment parfait pour souffler entre deux shows et repartir avec la tête pleine d’idées concrètes pour intégrer des gestes écoresponsables à ton quotidien. Définitivement un must pour celles et ceux qui veulent rendre le monde un peu plus beau!</h4>
            </div>

            {/* PROG */}
            <div>
                <FilteredActivities eventList={conferencesEvents} />
            </div>

        </div>
    </Wolflayout>
    )
}

export const query = graphql`
query {
    allPrismicEvents {
        edges {
            node {
                uid
                slug
                    data {
                        main_image {
                            alt
                            copyright
                            gatsbyImageData
                            url
                        }
                        title {
                            text
                        }
                        start_time
                        end_time
                        description {
                            text
                        }
                        lpdv_id {
                            text
                        }
                        is_show
                        free
                        isbienetre
                        isentrevue
                        isconference
                        isplaisirdivers
                        isfamille
                        hidefromprog
                        stage {
                            document {
                            ... on PrismicStages {
                                uid
                                slug
                                data {
                                    title {
                                        text
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
`

export default ConferencesEtAteliers